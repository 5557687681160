<template>
  <div class="contentWrap">
    <miaHeader />
    <div class="contentbox">
      <div class="content">
        <div class="tab_header">
          <!-- <div class="tab_title" :class="tabAactive === 1 ? 'tab_title_active' : ''" @click="tabAactive = 1">找回店铺编码</div> -->
          <div
            class="tab_title"
            :class="tabAactive === 2 ? 'tab_title_active' : ''"
            @click="tabAactive = 2"
          >
            找回密码
          </div>
        </div>
        <div class="form">
          <div v-if="tabAactive === 1">
            <el-steps
              style="margin: 30px 0px"
              :active="StepActive"
              align-center
            >
              <el-step title="手机号">
                <div slot="icon">
                  <i class="iconfont icon-shouji"></i>
                </div>
              </el-step>
              <el-step title="验证身份">
                <div slot="icon">
                  <i class="iconfont icon-shenfenshibie"></i>
                </div>
              </el-step>
              <el-step title="重置密码">
                <div slot="icon">
                  <i class="iconfont icon-zhongzhimima"></i>
                </div>
              </el-step>
              <el-step title="完成">
                <div slot="icon">
                  <i class="iconfont icon-dingdanwancheng"></i>
                </div>
              </el-step>
            </el-steps>
            <div :class="!selectFlag ? 'contentCard' : 'Card'">
              <div v-if="StepActive === 1">
                <el-form
                  ref="Form"
                  :model="Form"
                  :rules="rules"
                  label-width="80px"
                >
                  <el-form-item label="手机号码" prop="phone" key="phone">
                    <el-input
                      v-model="Form.phone"
                      placeholder="请输入注册时填写的手机号码"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <div class="buttonWrap">
                      <div class="submiButton" @click="nextStep('Form')">
                        下 一 步
                      </div>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
              <div v-if="StepActive === 2">
                <el-form
                  ref="Form"
                  :model="Form"
                  :rules="rules"
                  label-width="80px"
                >
                  <el-form-item label="手机号码" prop="phone" key="phone">
                    <span>{{ Form.phone }}</span>
                  </el-form-item>
                  <el-form-item label="验证码" prop="code" key="code">
                    <div class="verificationInput">
                      <el-input
                        v-model="Form.code"
                        placeholder="请填写4位短信验证码"
                      ></el-input>
                      <el-button
                        slot="verificationButton"
                        class="sendCode"
                        type="text"
                        :disabled="!ClickSendFlag"
                        @click="sendCode"
                        >{{ verificationText }}</el-button
                      >
                    </div>
                  </el-form-item>
                  <!-- <el-form-item v-if="isShowCaptcha">
                    <SmartCaptcha
                      ref="captcha"
                      scene="ic_login"
                      width="100%"
                      @success="smartCaptchaSuccess"
                    />
                  </el-form-item> -->
                  <el-form-item>
                    <div class="buttonWrap">
                      <div class="submiButton" @click="nextStep('Form')">
                        下 一 步
                      </div>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
              <div v-if="StepActive === 3">
                <div class="passwordTip">
                  <p class="first">如何提高密码的安全性？</p>
                  <p>密码长度必须为8-16位，且同时包含字母、数字</p>
                  <p>避免使用过于简单的密码，例如123456、password</p>
                  <p>避免使用人名或生日，例如zhangsan、zhangsan1990</p>
                  <p>避免使用手机号码，例如135 6677 8899</p>
                </div>
                <el-form
                  ref="Form"
                  :model="Form"
                  :rules="rules"
                  label-width="80px"
                >
                  <el-form-item label="新密码" prop="newPwd" key="newPwd">
                    <el-input
                      v-model="Form.newPwd"
                      placeholder="8-16位数字、字母组合"
                      show-password
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="确认密码"
                    prop="repeatPwd"
                    key="repeatPwd"
                  >
                    <el-input
                      v-model="Form.repeatPwd"
                      placeholder="重复上方的密码"
                      show-password
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <div class="buttonWrap">
                      <div class="submiButton" @click="nextStep('Form')">
                        下 一 步
                      </div>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
              <div v-if="StepActive === 4">
                <div>
                  <div style="width: 100%; text-align: center">
                    <h1>恭喜您，密码重置成功！</h1>
                  </div>
                  <div style="text-align: center; margin-top: 40px">
                    <el-button type="primary" @click="goLogin()"
                      >登 录</el-button
                    >
                  </div>
                </div>
                <!-- <div>
                  <div class="tip">
                    <span>您的账号：</span>
                    <el-link>{{ Form.phone }}</el-link
                    >，
                    <span>一共绑定了 {{ organization.length }} 个店铺 ， </span>
                    <el-button
                      type="text"
                      @click="$message.warning('该功能尚未开发')"
                      >点此发送信息至手机</el-button
                    >
                  </div>
                  <div
                    class="list"
                    style="margin-top: 20px"
                    v-loading="loading"
                  >
                    <el-table
                      v-if="organization.length !== 0"
                      :data="organization"
                      border
                      height="35vh"
                      style="width: 100%"
                    >
                      <el-table-column
                        prop="officeCode"
                        label="店铺编码"
                      ></el-table-column>
                      <el-table-column
                        prop="officeName"
                        label="店铺名称"
                      ></el-table-column>
                      <el-table-column label="操作">
                        <template slot-scope="scope">
                          <el-button @click="goLogin(scope.row)" type="text"
                            >登录</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div v-if="tabAactive === 2">
            <ForgetPwd />
          </div>
        </div>
      </div>
    </div>
    <!-- <miaFooter /> -->
  </div>
</template>
<script>
import SmartCaptcha from "@/components/smartCaptcha/index.vue";
import ForgetPwd from "./GetBackPwd.vue";
import {
  GetVerificationCode,
  GetOrganizationCode,
  changePassword,
  checkCode,
} from "@/api/common.js";
import md5 from "md5";
export default {
  components: {
    SmartCaptcha,
    ForgetPwd,
  },
  data() {
    const validatePwd = (rule, value, callback) => {
      const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
      if (value !== "") {
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("请设置8-16位数字、字母组合的密码"));
        }
      } else {
        callback(new Error("请填写新密码"));
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.Form.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (value !== "") {
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("请填写正确的手机号"));
        }
      } else {
        callback(new Error("请填写手机号码"));
      }
    };
    return {
      tabAactive: 1,
      StepActive: 1,
      Form: {
        phone: "",
        code: "",
        tokenId: "",
        newPwd: "",
        repeatPwd: "",
      },
      rules: {
        phone: [{ required: true, validator: validatePhone }],
        code: [
          { required: true, message: "请填写6位短信验证码", trigger: "blur" },
          {
            min: 1,
            max: 6,
            message: "验证码格式有误，请重新输入",
            trigger: "blur",
          },
        ],
        newPwd: [{ validator: validatePwd, trigger: "blur" }],
        repeatPwd: [{ validator: validatePass2, trigger: "blur" }],
      },
      // 人机验证
      isShowCaptcha: false,
      valiteCodeData: null,
      icCap: null,
      ClickSendFlag: true,
      verificationText: "发送验证码",
      time: 60,
      // 计时器
      timepiece: null,
      // 选择店铺编码页面
      selectFlag: false,
      // 店铺编码
      organization: [],
      currentOrgId: null,
      loading: false,
    };
  },
  watch: {
    time(val) {
      if (val === 0) {
        this.resetTime();
      }
    },
    StepActive(value) {
      if (value === 2) {
        // 重置人机验证的显示
        this.isShowCaptcha = false;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timepiece);
  },
  methods: {
    // 立即登录
    promptlyLogin() {
      sessionStorage.removeItem("registerItem");
      this.$router.push({ name: "Login" });
    },
    // 下一步
    nextStep(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.StepActive === 1) {
            this.StepActive = 2;
          } else if (this.StepActive === 2) {
            this.loading = true;
            // this.getOrgList();
            checkCode({
              phone: this.Form.phone,
              code: this.Form.code,
            }).then((res) => {
              if (res.code === 1) {
                this.Form.tokenId = res.data;
              } else {
                this.$message.error(res.msg);
              }
              this.loading = false;
            });
            this.StepActive = 3;
          } else if (this.StepActive === 3) {
            this.loading = true;
            changePassword({
              phone: this.Form.phone,
              tokenId: this.Form.tokenId,
              newPassword: md5(this.Form.newPwd),
            }).then((res) => {
              if (res.code == 1) {
                this.StepActive = 4;
              } else {
                this.$message.error(res.msg);
              }
              this.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    // 发送验证码
    sendCode() {
      // this.isShowCaptcha = true;
      const form = { mobile: this.Form.phone, type: 3 };
      GetVerificationCode(form).then((res) => {
        if (res.code === 1) {
          this.Form.tokenId = res.data;
          // 发送成功
          this.$message.success("验证码发送成功，请注意查收");
          this.ClickSendFlag = false;
          this.verificationText = this.time + "s后可重新发送";
          this.timepiece = setInterval(this.changeTime, 1000);
        } else {
          this.resetTime();
          this.$message.error(res.msg);
        }
      });
    },
    // 人机验证成功
    smartCaptchaSuccess(data) {
      this.valiteCodeData = data.data;
      this.icCap = data.control;
      // 验证成功 发生验证码
      const form = {
        mobile:
          this.tabAactive == "1"
            ? this.Form.phone
            : this.retrievePassForm.phone,
        type: 3,
      };
      const result = { ...form, ...this.valiteCodeData };
      GetVerificationCode(result).then((res) => {
        if (res.code === 1 && res.data) {
          if (this.tabAactive == "1") {
            this.Form.tokenId = res.data;
          } else {
            this.retrievePassForm.tokenId = res.data;
          }
          this.$message.success("验证码发送成功，请注意查收");
          this.ClickSendFlag = false;
          this.verificationText = this.time + "s后可重新发送";
          this.timepiece = setInterval(this.changeTime, 1000);
        } else {
          this.resetTime();
          this.$message.error(res.msg);
        }
      });
      this.isShowCaptcha = false;
    },
    // 获取店铺list
    getOrgList() {
      GetOrganizationCode(this.Form).then((res) => {
        if (res.data && res.code === 1) {
          this.StepActive = 3;
          this.selectFlag = true;
          this.organization = res.data;
          this.loading = false;
        } else {
          this.$message.error(res.msg);
          this.loading = false;
        }
      });
    },
    changeTime() {
      this.time -= 1;
      this.verificationText = this.time + "s后可重新发送";
    },
    resetTime() {
      clearInterval(this.timepiece);
      this.verificationText = "重新发送";
      this.time = 60;
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      this.ClickSendFlag = true;
    },
    // 登录
    goLogin(data) {
      // const message = {
      //   officeCode: data.officeCode,
      //   loginName: this.Form.phone,
      // };
      // sessionStorage.setItem("registerItem", JSON.stringify(message));
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
<style lang="scss" scoped>
.contentWrap {
  width: 100vw;
  height: 100vh;
  background-color: #f5f8fd;
  .contentbox {
    width: calc(100% - 20%);
    padding: 5% 10% 0%;
    // min-height: calc(100% - 35vh);
    .content {
      width: 90%;
      min-height: 65vh;
      background: #fff;
      position: relative;
      padding: 3% 5%;
      margin-bottom: 10px;
      .tab_header {
        width: 100%;
        height: 44px;
        position: absolute;
        top: -41px;
        left: 0;
        display: inline-flex;
        align-items: center;
        justify-content: start;
        .tab_title {
          width: 146px;
          height: 100%;
          display: inline-flex;
          align-items: center;
          color: #666666;
          font-size: 16px;
          text-align: center;
          cursor: pointer;
          justify-content: center;
        }
        .tab_title_active {
          // background-image: url('../../assets/image/tab_bg.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% 90%;
          color: #308ffa;
          font-weight: bold;
        }
      }
      .form {
        width: 100%;
        /deep/ .is-finish {
          .el-step__icon {
            width: 50px;
            height: 50px;
            background: #479bf9;
            // border: 1px #CEDBEF solid;
            .iconfont {
              font-size: 20px;
              color: #fff;
            }
          }
        }
        /deep/ .is-process {
          .el-step__icon {
            .iconfont {
              font-size: 20px;
            }
          }
        }
        /deep/ .el-step__icon {
          width: 50px;
          height: 50px;
          background: #eff6fe;
          border: 1px #cedbef solid;
          .iconfont {
            font-size: 20px;
          }
        }
        /deep/ .el-step__line {
          top: 24px;
          height: 1px;
          background: #e1eaf9;
        }
        /deep/ .el-step__title {
          color: #222;
        }
        .contentCard,
        .Card {
          width: 50%;
          margin: 50px auto;
          .buttonWrap {
            width: 100%;
            height: 10vh;
            margin-top: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            .submiButton {
              width: 60%;
              height: 50px;
              margin: 0 auto;
              background: #2f8ffa;
              border-radius: 4px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-size: 18px;
              cursor: pointer;
            }
          }
        }
        .Card {
          width: 100%;
        }
      }
      .sumbitForm {
        /deep/ .distpicker-address-wrapper {
          label {
            select {
              width: 32%;
            }
          }
          margin-bottom: 20px;
        }
        .tip {
          width: 1px;
          height: 40vh;
          background: #f1f3f9;
          margin: 0 auto;
        }
        /deep/ .avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        /deep/ .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        /deep/ .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 178px;
          height: 178px;
          line-height: 178px;
          text-align: center;
        }
        /deep/ .avatar {
          width: 178px;
          height: 178px;
          display: block;
        }
      }
      .registerTip {
        width: 100%;
        text-align: center;
        display: inline-flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        div {
          width: 100%;
        }
        .sumbitBotton {
          width: 386px;
          height: 50px;
          line-height: 50px;
          background: #2f8ffa;
          border-radius: 4px;
          cursor: pointer;
          font-size: 18px;
          color: #ffffff;
          margin: 0 auto;
          margin-top: 10px;
        }
      }
    }
    .clearfix {
      text-align: left;
    }
    .successWrap {
      width: 80%;
      min-height: 40vh;
      display: inline-flex;
      flex-wrap: wrap;
      align-content: center;
      // background-color: chocolate;
      text-align: center;
      p {
        width: 100%;
        padding: 10px 30%;
        text-align: left;
      }
    }
  }
}
.mia-footer {
  position: fixed;
  bottom: 0%;
}
.verificationInput {
  position: relative;
  .sendCode {
    position: absolute;
    right: 3%;
  }
}
.passwordTip {
  border: 1px solid #cedbef;
  padding: 10px 20px;
  margin-bottom: 20px;
  p {
    font-size: 14px;
  }
  .first {
    color: #187ae8;
    font-size: 16px;
    margin-bottom: 5px;
  }
}
</style>
