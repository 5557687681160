<template>
    <div>                    
        <el-steps style="margin-top:30px" :active="StepActivePass" align-center>
            <el-step title="基础信息">
                <div slot="icon">
                    <i class="iconfont icon-shouji"></i>
                </div>
            </el-step>
            <el-step title="重置密码">
                <div slot="icon">
                    <i class="iconfont icon-zhongzhimima"></i>
                </div>
            </el-step>
            <el-step title="完成">
                <div slot="icon">
                    <i class="iconfont icon-dingdanwancheng"></i>
                </div>
            </el-step>
        </el-steps>
        <div class="contentCard">
            <div v-if="StepActivePass !== 3">
                <el-form ref="retrievePassForm" style="margin:20px auto;width:70%;" :model="retrievePassForm" :rules="retrievePassRules" label-width="80px">
                    <el-alert
                        v-if="StepActivePass === 2"
                        title="如何提高密码的安全性？"
                        type="warning"
                        :closable="false"
                        description="密码长度必须为8-16位，且同时包含字母、数字避免使用过于简单的密码，例如123456、password避免使用人名或生日，例如zhangsan、zhangsan1990避免使用手机号码，例如135 6677 8899">
                    </el-alert>
                    <el-form-item label="店铺编号" prop="officeCode">
                        <el-input v-if="StepActivePass === 1" v-model="retrievePassForm.officeCode" placeholder="请填写店铺编号"></el-input>
                        <span v-if="StepActivePass === 2">{{retrievePassForm.officeCode}}</span>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="phone">
                        <el-input v-if="StepActivePass === 1" v-model="retrievePassForm.phone" placeholder="请填写手机号码"></el-input>
                        <span v-if="StepActivePass === 2">{{retrievePassForm.phone}}</span>
                    </el-form-item>
                    <el-form-item v-if="StepActivePass === 2" label="验证码" prop="code">
                        <div class="verificationInput" >
                            <el-input readonly onfocus="this.removeAttribute('readonly')" @input="$forceUpdate()"  v-model="retrievePassForm.code" placeholder="请填写4位短信验证码" autocomplete="off"></el-input>
                            <el-button class="sendCode" type="text" :disabled="!ClickSendFlag" @click="sendCode">{{verificationText}}</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item v-if="isShowCaptcha">
                        <SmartCaptcha 
                            v-if="isShowCaptcha"
                            ref="captcha"
                            scene="ic_login"
                            width="100%"
                            @success="smartCaptchaSuccess"
                        />
                    </el-form-item>
                    <el-form-item v-if="StepActivePass === 2" label="新密码" prop="newPassword">
                        <el-input readonly onfocus="this.removeAttribute('readonly')" @input="$forceUpdate()" v-model="retrievePassForm.newPassword" placeholder="8-16位数字、字母组合" show-password></el-input>
                    </el-form-item>
                    <el-form-item v-if="StepActivePass === 2" label="确认密码" prop="affirmPassword">
                        <el-input readonly onfocus="this.removeAttribute('readonly')" @input="$forceUpdate()"  v-model="retrievePassForm.affirmPassword" placeholder="重复上方的密码" show-password></el-input>
                    </el-form-item>
                    <el-form-item>
                        <div class="buttonWrap">
                            <div v-if="StepActivePass === 1" class="submiButton" @click="Finish('retrievePassForm', 1)"> 下 一 步 </div>
                            <div v-if="StepActivePass === 2" class="submiButton" @click="Finish('retrievePassForm', 2)"> 下 一 步 </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div v-else>
                <div class="successWrap">
                    <div class="bg"></div>
                    <div class="tiptitle">恭喜您，密码重置成功！</div>
                    <div class="buttonWrap" style="margin-top:20px">
                        <div class="submiButton" @click="goLogin('retrievePassForm', 3)"> 去 登 录 </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SmartCaptcha from '@/components/smartCaptcha/index.vue'
import { GetVerificationCode, changePassword } from '@/api/common.js'
import md5 from 'md5'
export default {
    components: {
        SmartCaptcha,
    },
    data(){
        const validatePhone = (rule, value, callback) => {
        const reg = /^[1][3,4,5,7,8][0-9]{9}$/
        if (value !== '') {
            if (reg.test(value)) {
            callback()
            } else {
            callback(new Error('请填写正确的手机号'))
            }
        } else {
            callback(new Error('请填写手机号码'))
        }
        }
        const validatePwd = (rule, value, callback) => {
            const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
            if (value !== '') {
                if (reg.test(value)) {
                    callback()
                } else {
                    callback(new Error('请设置8-16位数字、字母组合的密码'))
                }
            } else {
                callback(new Error('请填写新密码'))
            }
        }
        const validateAffirmPwd = (rule, value, callback) => {
            if (this.retrievePassForm.newPassword && this.retrievePassForm.newPassword !== value) {
                callback(new Error('确认密码与新密码不一致'))
            } else {
                // this.$refs['retrievePassForm'].clearValidate('newPassword')
                callback()
            }
        }
        return{
            // 找回密码的步骤条激活
            StepActivePass: 1,
            // 找回密码页
            retrievePassForm:{
                officeCode: '',
                phone:'',
                code:'',
                tokenId:'',
                newPassword:'',
                affirmPassword:'',
            },
            Form:{
                code:'',
                newPassword:'',
                affirmPassword:'',
            },
            retrievePassRules:{
                officeCode:[
                    { required: true, message: '请填写店铺编号', trigger: 'blur' },
                    { min: 1, max: 5, message: '长度在 1 到 5 个字符', trigger: 'blur' }
                ],
                phone:[
                    { required: true, validator: validatePhone }
                ],
                code:[
                    { required: true, message: '短信验证码不能为空', trigger: 'blur' },
                    { min: 1, max: 4, message: '短信验证码格式错误', trigger: 'blur' }
                ],
                newPassword:[
                    { required: true, message: '请填写新密码', trigger: 'blur' },
                    { required: true, trigger: 'blur', validator: validatePwd }
                ],
                affirmPassword:[
                    { required: true, message: '请填写确认密码', trigger: 'blur' },
                    { required: true, trigger: 'blur', validator: validateAffirmPwd }
                ]
            },
            // 人机验证
            isShowCaptcha: false,
            valiteCodeData: null,
            icCap: null,
            ClickSendFlag: true,
            verificationText: '发送验证码',
            time: 60,
            // 计时器
            timepiece: null,
        }
    },
    watch:{
        time(val) {
            if(val === 0) {
                this.resetTime()
            }
        },
        StepActivePass(val){
            if(val === 2) {
                this.retrievePassForm.code = ''
                this.retrievePassForm.newPassword = ''
            }
        }
    },
    methods:{
        // 找回密码页的下一步
        Finish(formName, type){
            if(type === 1) {
                if(this.retrievePassForm.officeCode !== '' &&  this.retrievePassForm.phone !== '') {
                    this.StepActivePass = 2
                    this.retrievePassForm.code = ''
                    this.retrievePassForm.newPassword = ''
                    return
                } else {
                    this.$message.error('请完善基础信息')
                }
            } else if(type === 2){
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    const form = JSON.parse(JSON.stringify(this.retrievePassForm))
                        delete form.affirmPassword
                        form.newPassword = md5(this.retrievePassForm.newPassword)
                        changePassword(form).then(res => {
                            if(res.code === 1) {
                                this.StepActivePass = 3
                            } else {
                                this.$message.error(res.msg)
                            }
                    })
                } else {
                    return false;
                }
            });
            }
        },
        // 发送验证码
        sendCode(){
            this.isShowCaptcha = true
        },
        // 人机验证成功
        smartCaptchaSuccess(data){
            this.valiteCodeData = data.data
            this.icCap = data.control
            // 验证成功 发生验证码
            const form = {mobile: this.retrievePassForm.phone, type : 3}
            const result = { ...form, ...this.valiteCodeData}
            GetVerificationCode(result).then(res => {
            if(res.code === 1 && res.data) {
                this.retrievePassForm.tokenId = res.data               
                this.$message.success('验证码发送成功，请注意查收')
                this.ClickSendFlag = false
                this.verificationText = this.time + 's后可重新发送' 
                this.timepiece = setInterval(this.changeTime,1000)
            } else {
                this.resetTime()
                this.$message.error(res.msg)
            }
         })
         this.isShowCaptcha = false
        },
        changeTime(){
            this.time -= 1
            this.verificationText = this.time + 's后可重新发送' 
        },
        resetTime(){
          clearInterval(this.timepiece)
          this.verificationText = '重新发送'
          this.time = 60
          const reg = /^[1][3,4,5,7,8][0-9]{9}$/
          this.ClickSendFlag = true
        },
        // 登录
        goLogin(){
            const message = { officeCode: this.retrievePassForm.officeCode, loginName: this.retrievePassForm.phone }
            sessionStorage.setItem('registerItem',JSON.stringify(message))
            this.$router.push({name: 'Login'})
        }
    }
}
</script>
<style lang="scss" scoped>
.contentWrap{
    width: 100vw;
    height: 100vh;
    .header{
        width: 80%;
        height: 10vh;
        padding: 0 10%;
        text-align: left;
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        //#eef6ff #e0eefe
        background-color: #eef6ff;
        .title{
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            height: 100%;
            .name, .tip{
                width: 100%;
            }
        }
    }
    .contentbox{
        width: calc(100% - 20%);
        padding: 3% 10%;
        min-height: calc(100% - 40vh);  
        .contentCard,.Card{
            width: 50%;
            margin: 0 auto;
            min-height: 30vh;
            padding: 5% 10% 3%;
            .successWrap{
                width: 100%;
                height: 100%;
                .bg{
                    width: 100%;
                    height: 30vh;
                    background-image: url('../../assets/image/success.png');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 50%;
                    color: #308FFA;
                    font-weight: bold;
                }
                .tiptitle{
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    color: #222222;
                }
            }
            .buttonWrap{
                width: 100%;
                height: 10vh;
                margin-top: 40px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                .submiButton{
                    width: 60%;
                    height: 50px;
                    margin:  0 auto;
                    background: #2F8FFA;
                    border-radius: 4px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-size: 18px;
                    cursor: pointer;
                }
            }
        }
        .Card{
            width: 70%;
            margin: 0 auto;
            min-height: 30vh;
            padding: 5% 0%;
        }
    }
}
.verificationInput{
  position: relative;
  .sendCode{
    position: absolute;
    right: 3%;
  }
}
/deep/ .el-alert--warning.is-light {
    background-color: #EFF6FE;
    color: #187AE8;
}
/deep/ .el-alert--warning.is-light .el-alert__description {
    color: #333;
}
</style>